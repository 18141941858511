import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/investiture/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/investiture/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/investiture/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/investiture/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/2024/investiture/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2024/investiture/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2024/investiture/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2024/investiture/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2024/investiture/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2024/investiture/10.jpg';
// import p11 from 'assests/Photos/SchoolProgram/2024/investiture/11.jpg';
// import p12 from 'assests/Photos/SchoolProgram/2024/investiture/12.jpg';
// import p13 from 'assests/Photos/SchoolProgram/2024/investiture/13.jpg';
// import p14 from 'assests/Photos/SchoolProgram/2024/investiture/14.jpg';
// import p15 from 'assests/Photos/SchoolProgram/2024/investiture/15.jpg';
// import p16 from 'assests/Photos/SchoolProgram/2024/investiture/16.jpg';
// import p17 from 'assests/Photos/SchoolProgram/2024/investiture/17.jpg';
// import p18 from 'assests/Photos/SchoolProgram/2024/investiture/18.jpg';



// import p5 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2023/RepublicDay/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2023/RepublicDay/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2023/RepublicDay/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2023/RepublicDay/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2023/RepublicDay/10.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Investiture2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/school-programs/investiture/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/investiture/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/investiture/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/investiture/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/investiture/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/investiture/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/investiture/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/investiture/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/investiture/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/investiture/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/investiture/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/investiture/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/investiture/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/investiture/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/investiture/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/investiture/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/investiture/17.webp`;
  
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 1,
        }, {
            src: p5,
            source: p5,
            rows: 2,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 1,
        }, 
        {
            src: p7,
            source: p7,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1.5,
            cols: 1,
        },
        {
          src: p10,
          source: p10,
          rows: 1.5,
          cols: 1,
        }, 
        {
        src: p11,
        source: p11,
        rows: 1.5,
        cols: 2,
       },
       {
        src: p12,
        source: p12,
        rows: 1.5,
        cols: 2,
       },
       {
        src: p13,
        source: p13,
        rows: 1.5,
        cols: 2,
       },
       {
        src: p14,
        source: p14,
        rows: 1.5,
        cols: 2,
       }, 
       {
        src: p15,
        source: p15,
        rows: 2.6,
        cols: 2,
       },
       {
        src: p16,
        source: p16,
        rows: 1.7,
        cols: 2,
       }, 
       {
        src: p17,
        source: p17,
        rows: 1.5,
        cols: 2,
        },


    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Investiture Ceremony
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Date: 21 June 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur embarked on a new academic year 2024-25 with Investiture Ceremony on Friday, 21 June 2024. It marks a significant milestone in the journey of nurturing leadership and excellence among our students. This ceremony is not merely a ritual of conferring titles; it is a solemn occasion where we entrust responsibilities to our future leaders.  
                                    <br></br>
                                    The Investiture Ceremony for the academic year 2024-25 commenced with a proud display of discipline and honour as the cabinet marched in synchronization to the beats of the school band and accorded the guard of honour to our Principal Ms Sheeja Manoj.  The ceremony took an intense turn as each leader stood before their peers, were adorned with badges and flags ready to accept the weight of their duties and responsibilities. With heads held high and hearts filled with determination, they pledged to uphold the values and principles of our  who embody the essence of true leadership to know the way, go the way and show the way. They have been chosen not only for their abilities but for their potential to positively influence others. She further emphasised that, “If you truly desire something, the universe conspires to make it happen. As we entrust these responsibilities upon you, I urge you to lead with integrity, empathy and in a relentless pursuit of excellence”. The Principal also acknowledged the role of the parents in nurturing these values and desired skill sets that paved the way of success for these students.

                                    <br></br>
                                    Students were bestowed with badges in recognition of their roles as various association leaders.
                                    <br></br>
                                    The ceremony concluded with a sense of optimism and anticipation for the academic year 2024-25, as the newly invested leaders embraced their roles with enthusiasm and determination. As they commence on this journey of leadership, guided by the wisdom of faculty and supported by the encouragement of their peers and families, they carry with them the aspirations and dreams of a brighter future for all.

                                   
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ Leadership is the capacity to turn vision into reality ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Investiture2024;